// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agradecimiento-js": () => import("./../../../src/pages/agradecimiento.js" /* webpackChunkName: "component---src-pages-agradecimiento-js" */),
  "component---src-pages-alianzas-js": () => import("./../../../src/pages/alianzas.js" /* webpackChunkName: "component---src-pages-alianzas-js" */),
  "component---src-pages-ccoe-js": () => import("./../../../src/pages/ccoe.js" /* webpackChunkName: "component---src-pages-ccoe-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-encuesta-de-satisfaccion-encuesta-agradecimiento-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/encuesta-agradecimiento.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-encuesta-agradecimiento-js" */),
  "component---src-pages-encuesta-de-satisfaccion-encuesta-formulario-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/encuesta-formulario.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-encuesta-formulario-js" */),
  "component---src-pages-encuesta-de-satisfaccion-encuesta-mensaje-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/encuesta-mensaje.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-encuesta-mensaje-js" */),
  "component---src-pages-encuesta-de-satisfaccion-encuesta-opciones-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/encuesta-opciones.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-encuesta-opciones-js" */),
  "component---src-pages-encuesta-de-satisfaccion-encuesta-preguntas-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/encuesta-preguntas.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-encuesta-preguntas-js" */),
  "component---src-pages-encuesta-de-satisfaccion-login-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/login.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-login-js" */),
  "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-agradecimiento-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/templates/encuesta-agradecimiento.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-agradecimiento-js" */),
  "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-formulario-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/templates/encuesta-formulario.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-formulario-js" */),
  "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-login-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/templates/encuesta-login.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-login-js" */),
  "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-mensaje-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/templates/encuesta-mensaje.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-mensaje-js" */),
  "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-opciones-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/templates/encuesta-opciones.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-opciones-js" */),
  "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-preguntas-js": () => import("./../../../src/pages/encuesta-de-satisfaccion/templates/encuesta-preguntas.js" /* webpackChunkName: "component---src-pages-encuesta-de-satisfaccion-templates-encuesta-preguntas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landings-bimov-jsx": () => import("./../../../src/pages/landings/bimov.jsx" /* webpackChunkName: "component---src-pages-landings-bimov-jsx" */),
  "component---src-pages-landings-ccoe-jsx": () => import("./../../../src/pages/landings/ccoe.jsx" /* webpackChunkName: "component---src-pages-landings-ccoe-jsx" */),
  "component---src-pages-landings-cloudservices-jsx": () => import("./../../../src/pages/landings/cloudservices.jsx" /* webpackChunkName: "component---src-pages-landings-cloudservices-jsx" */),
  "component---src-pages-landings-ingenieria-software-jsx": () => import("./../../../src/pages/landings/ingenieria-software.jsx" /* webpackChunkName: "component---src-pages-landings-ingenieria-software-jsx" */),
  "component---src-pages-landings-staffing-jsx": () => import("./../../../src/pages/landings/staffing.jsx" /* webpackChunkName: "component---src-pages-landings-staffing-jsx" */),
  "component---src-pages-landings-ventsupport-jsx": () => import("./../../../src/pages/landings/ventsupport.jsx" /* webpackChunkName: "component---src-pages-landings-ventsupport-jsx" */),
  "component---src-pages-politicas-de-calidad-js": () => import("./../../../src/pages/politicas-de-calidad.js" /* webpackChunkName: "component---src-pages-politicas-de-calidad-js" */),
  "component---src-pages-politicas-de-privacidad-js": () => import("./../../../src/pages/politicas-de-privacidad.js" /* webpackChunkName: "component---src-pages-politicas-de-privacidad-js" */),
  "component---src-pages-presencia-js": () => import("./../../../src/pages/presencia.js" /* webpackChunkName: "component---src-pages-presencia-js" */),
  "component---src-pages-proceso-js": () => import("./../../../src/pages/proceso.js" /* webpackChunkName: "component---src-pages-proceso-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-soluciones-bimov-jsx": () => import("./../../../src/pages/soluciones/bimov.jsx" /* webpackChunkName: "component---src-pages-soluciones-bimov-jsx" */),
  "component---src-pages-soluciones-ingenieria-de-software-jsx": () => import("./../../../src/pages/soluciones/ingenieria-de-software.jsx" /* webpackChunkName: "component---src-pages-soluciones-ingenieria-de-software-jsx" */),
  "component---src-pages-soluciones-ventsupport-jsx": () => import("./../../../src/pages/soluciones/ventsupport.jsx" /* webpackChunkName: "component---src-pages-soluciones-ventsupport-jsx" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-templates-soluciones-js": () => import("./../../../src/templates/soluciones.js" /* webpackChunkName: "component---src-templates-soluciones-js" */)
}

